<template>
	<standard-container title="Error" description="" icon="mdi-error">
		Errore generico
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** Generic Error ***********************************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
export default {
	name: 'ErrorPage',
	components: { StandardContainer },
	data: () => {
		return {
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	mounted() {
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>